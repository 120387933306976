<template>
  <div class="login_page">
    <div class="login_card">
      <div
        class="min-h-screen flex items-center justify-center bg-gray-50 py-12 px-4 sm:px-6 lg:px-8 card"
      >
        <div class="max-w-md w-full space-y-8">
          <div class="card-img-top">
            <img class="mx-auto h-56 w-auto" :src="logo" alt="encom_logo" />
            <h2 class="mt-10 text-center text-3xl font-extrabold text-gray-900">
              Sign in to your account
            </h2>
          </div>
          <div class="form_card">
            <form
              class="mt-6 space-y-6"
              @submit.prevent="onSubmit"
              method="POST"
            >
              <input type="hidden" name="remember" value="true" />
              <div class=" shadow-sm -space-y-px">
                <div>
                  <label for="email-address" class="sr-only email_label"
                    >Email address</label
                  >
                  <input
                    id="email-address"
                    v-model="form.email"
                    name="email"
                    type="email"
                    autocomplete="email"
                    required=""
                    class="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 focus:outline-none focus:encom_border_input  focus:z-10 sm:text-sm"
                    placeholder="Email address"
                  />
                </div>
                <div>
                  <label for="password" class="sr-only">Password</label>
                  <input
                    id="password"
                    v-model="form.password"
                    name="password"
                    type="password"
                    autocomplete="current-password"
                    required=""
                    class="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 focus:outline-none focus:encom_border_input  focus:z-10 sm:text-sm"
                    placeholder="Password"
                  />
                </div>
              </div>

              <div class="flex items-center justify-between">
                <div class="text-sm">
                  <a href="#" class="font-medium encom_ouvert_ticket_text">
                    New password
                  </a>
                </div>

                <div class="text-sm forgot_margin">
                  <a href="#" class="font-medium encom_ouvert_ticket_text">
                    Forgot your password
                  </a>
                </div>
              </div>

              <div>
                <button
                  type="submit"
                  class="rounded body__table--header group relative w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium text-white focus:outline-none encom_ring_color"
                >
                  <span
                    class="absolute left-0 inset-y-0 flex items-center pl-3"
                  >
                    <LockClosedIcon
                      class="h-5 w-5 primary_text group-hover:encom_secondary_text"
                      aria-hidden="true"
                    />
                  </span>
                  Sign in
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import { LockClosedIcon } from "@heroicons/vue/solid";
import { mapGetters } from "vuex";


export default {
  components: { LockClosedIcon },
  name: "Login",
  data() {
    return {
      form: {
        email: "",
        password: "",
      },
    };
  },
  methods: {
    async onSubmit() {
      const data = {
        email: this.form.email,
        password: this.form.password,
      };

      axios
        .post(`${this.$cookie.getCookie("API")}/api/login`, data)
        .then((res) => {
          axios.defaults.headers.common["Authorization"] =
            "Bearer " + res.data.token;
          localStorage.setItem("token", res.data.token);
          this.$store.dispatch("token", res.data.token);
          localStorage.setItem("avatar", res.data.user.avatar);
          localStorage.setItem("userId", res.data.user.id);
          this.$store.dispatch("user", res.data.user);
          this.$store.dispatch("userId", res.data.user.id);
          this.$forceUpdate();
        })
        .then((res) => {
          this.$router.push(this.$route.query.to || "/");
        })
        .then((res) => {
          this.$emit("getTicketCount");
        })
        .catch((err) => {
          console.error(err);
          window.alert("Connection refused. Please try again later.");
          localStorage.removeItem("token");
          this.$store.dispatch("token", null);
          localStorage.removeItem("userId");
        });
    },
  },
  computed: {
    ...mapGetters(["logo"]),
  },
};
</script>

<style scoped>
.login_page {
  background: linear-gradient(rgba(0, 0, 0, 0.15), rgba(0, 0, 0, 0.15)),
    url("../assets/background/paris_background.jpg") no-repeat center center
      fixed;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  z-index: 0;
}
.login_card {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
}
#input-group-3__BV_label_ {
  margin-top: 0.2rem;
}
.d-block {
  font-weight: bold;
}
button {
  justify-items: auto;
  margin-inline-end: 0.5rem;
  margin-block-start: 0.5rem;
  margin-top: 0.6rem;
}

.card {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  max-width: 50rem;
  min-width: 30rem;
  /* border-radius: 10px; */
}
.card:hover {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.5), 0 6px 20px 0 rgba(0, 0, 0, 0.404);
}

.card-img-top img:hover {
  transform: scale(1.01);
}

.min-h-screen {
  min-height: 30vh;
}
.bg-gray-50 {
  background-color: rgba(255, 255, 255, 0.733);
}
.form_card {
  /* background-color: rgba(255, 255, 255, 0.733); */
  /* box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5), 0 3px 10px 0 rgba(0, 0, 0, 0.404); */
  transition: 0.3s;
  padding: 0.1rem 10px 10px 10px;
  /* border-radius: 10px; */
}
.form_card #email-address {
  margin-top: -10px;
  margin-bottom: 10px;
}

.forgot_margin a {
  margin-left: 50px;
}

@media only screen and (max-width: 700px) {
  .form_card {
    max-height: 100vh;
    max-width: 50vh;
  }
  .login_page {
    overflow-x: hidden;
    overflow-y: hidden;
  }
  .max-w-md {
    max-height: 100vh;
    max-width: fit-content;
  }
  .max-w-md img {
    max-height: 150px;
  }
}
</style>
